import { ThemeOptions, alpha } from '@mui/material';
import typography from '../utils/typography';
import { createShadow, createCustomShadow } from '../utils/shadows';
import { CustomShadowOptions, CustomThemeVars } from '../theme.types';
import { generateGradient } from '../utils/gradients';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    gs: CustomThemeVars;
    blue?: typeof BLUE;
    yellow?: typeof YELLOW;
    green?: typeof GREEN;
    red?: typeof RED;
  }
  interface Palette {
    gs: CustomThemeVars;
    blue?: typeof BLUE;
    yellow?: typeof YELLOW;
    green?: typeof GREEN;
    red?: typeof RED;
  }
  interface TypeBackground {
    neutral: string;
    gradient?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
    gradient?: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
    gradient: string;
  }
  interface Theme {
    customShadows: CustomShadowOptions;
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions;
  }
}

export const GREY = {
  '0': '#FFFFFF',
  '100': '#F9FAFB',
  '200': '#F4F6F8',
  '300': '#DFE3E8',
  '400': '#C4CDD5',
  '500': '#919EAB',
  '600': '#637381',
  '700': '#454F5B',
  '800': '#212B36',
  '900': '#161C24',
};

export const BLUE = {
  main: generateGradient(90, ['#CCDFFF', 0], ['#FFFFFF', 80], ['#FFFFFF', 100]),
  dark: '#212B36',
  darkGradient: generateGradient(90, ['#DFE3E8', 0], ['#DFE3E8', 80], ['#DFE3E8', 100]),
};

export const YELLOW = {
  main: generateGradient(90, ['#FFF5CC', 0], ['#FFFFFF', 80], ['#FFFFFF', 100]),
  dark: '#7A4100',
  darkGradient: generateGradient(90, ['#FFD666', 0], ['#DFE3E8', 80], ['#DFE3E8', 100]),
};

export const GREEN = {
  main: generateGradient(90, ['#D4EDDA', 0], ['#FFFFFF', 80], ['#FFFFFF', 100]),
  dark: '#1B5E20',
  darkGradient: generateGradient(90, ['#A5D6A7', 0], ['#81C784', 50], ['#4CAF50', 100]),
};

export const RED = {
  main: generateGradient(90, ['#FFCDD2', 0], ['#FFFFFF', 80], ['#FFFFFF', 100]),
  dark: '#B71C1C',
  darkGradient: generateGradient(90, ['#E57373', 0], ['#F44336', 50], ['#D32F2F', 100]),
};

const PRIMARY = {
  lighter: '#455E7D',
  light: '#74869D',
  main: '#17365C',
  middle: '#17365C',
  dark: '#0E2037',
  darker: '#122B4A',
  contrastText: '#fff',
  gradient: generateGradient(180, ['#74869D', 0], ['#17365C', 100]),
};

const SECONDARY = {
  lighter: '#d6e4ff',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
  gradient: generateGradient(180, ['#5BBABF', 0], ['#117E83', 100]),
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#008299',
  middle: '#919EAB29',
  dark: '#1C5F7B',
  darker: '#003768',
  contrastText: '#fff',
  gradient: generateGradient(180, ['#5BBABF', 0], ['#117E83', 100]),
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#1C826C',
  middle: '#28865F',
  dark: '#166957',
  darker: '#0A5554',
  contrastText: GREY[800],
  gradient: generateGradient(180, ['#86E8AB', 0], ['#36B37E', 100]),
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#B35C00',
  middle: '#919EAB29',
  dark: '#954D00',
  darker: '#7A4100',
  contrastText: '#FFFFFF',
  gradient: generateGradient(180, ['#FFD666', 0], ['#FFAB00', 100]),
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#D93A17',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
  gradient: generateGradient(180, ['#FFAC82', 0], ['#FF5630', 100]),
};

const BACKGROUND_GRADIENT = generateGradient(180, ['#F7F7F7', 0], ['#F1F4F8', 100]);

const baseShadows = createShadow(GREY['500']);
const baseThemeOptions: ThemeOptions = {
  palette: {
    common: { black: '#000000', white: '#FFFFFF' },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    blue: BLUE,
    red: RED,
    green: GREEN,
    yellow: YELLOW,
    divider: alpha(GREY[500], 0.24),
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
      gradient: BACKGROUND_GRADIENT,
    },
    action: {
      hover: alpha(GREY[500], 0.2),
      selected: alpha(GREY[500], 0.16),
      disabled: alpha(GREY[500], 0.8),
      disabledBackground: alpha(GREY[500], 0.24),
      focus: alpha(GREY[500], 0.24),
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      active: GREY[600],
      selectedOpacity: 0.2,
    },

    /**
     * Add custom variables here.
     *
     * Before you define custom variables, declare them in the CustomThemeVars interface
     */
    gs: {
      navigation: {
        background: PRIMARY.main,
        primary: '#fff',
        color: '#fff',
        logo: {
          fill: undefined,
        },
        action: {
          active: {
            background: alpha(GREY['500'], 0.16),
          },
        },
      },
    },
  },
  typography,
  shape: { borderRadius: 8 },
  shadows: baseShadows,
};

baseThemeOptions.customShadows = createCustomShadow(GREY['500'], baseThemeOptions.palette);

export { baseThemeOptions };
